export default {
    profil: {
        index: 'Mon compte',
    },
    utilisateurs: {
        first_name: 'Prénom',
        last_name: 'Nom',
        organization: {
            index: 'Structure',
            name: 'Structure',
            v: 'Structure',
        },
    },
}
